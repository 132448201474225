import React from 'react';

import './Logo.styl'

interface LogoProps {
}

const Logo: React.FunctionComponent = (props: LogoProps) => {
	const {project} = process.env;
	switch (project) {
		case "silpo" :
			return (
				<a href="https://silpo.ua">
					<span id="logo" className="logo">
						<svg version="1.1" x="0px" y="0px" viewBox="0 0 735 291.3">
				<title>silpo.ua</title>
				<g>
					<path fill="#F47B20" d="M303.2,6c-33.1,0.8-56.2,23.5-67.1,36.9c0,0-0.5,0.6-0.9,1.1c-0.7-0.2-1.3-0.5-1.3-0.5
						c-22.7-8.2-48.3-9.7-74.1-4.5C135,44.1,99.3,56.9,73.5,77.8C28,114.5,4,158.7,6.1,202.3c1.2,25.2,12.8,47.1,33.6,63.1
						c34.1,26.3,90.4,20.3,113.1,16.5c25.3-4.3,422-75.4,474-85.3c47.4-9.1,92.5-25.1,101.5-81.1c0.5-3.3,0.8-6.7,0.8-10V105
						c0-13.7-4.3-26.5-11.7-35.2c-13.9-16.1-37.2-18-53.6-18c-22.6-0.1-265.9,4.6-293.6,5.1c0,0-0.9,0-1.8,0c-0.1-0.9-0.2-1.8-0.2-1.8
						c-1.5-11.1-6-21.2-13-29.2C346.8,16.5,331,5.3,303.2,6z"/>
					<path fill="#232E83" d="M303,0c-34.2,0.8-58.4,23.5-69.8,37c-23.1-7.8-48.9-9.1-74.6-3.9c-25.5,5.2-62.2,18.4-88.9,40
						C22.7,111.1-2,157.1,0.1,202.7c1.3,27,13.7,50.4,35.9,67.5c36,27.8,94.3,21.6,117.7,17.7c24.8-4.2,421.1-75.2,474.1-85.3
						c49.5-9.4,96.6-26.4,106.3-86c0.6-3.7,0.9-7.3,0.9-10.9c0.1-15.3-4.7-29.8-13.1-39.6c-15.5-18-40.6-20.1-58.1-20.1
						c-14,0-114.4,1.7-290.5,5.1c-2.1-11-6.8-20.9-13.7-28.8C350.4,11.6,333.1-0.7,303,0z M359.7,22.1L359.7,22.1L359.7,22.1z
						 M237.7,44.2C248.3,31,271,8.8,303.2,8c27-0.7,42.3,10.2,50.4,19.4c6.8,7.7,11.1,17.4,12.5,28.1l0.4,3.6l3.6-0.1
						c27.7-0.5,270.8-5.2,293.6-5.1c15.9,0,38.7,1.9,52,17.4c7.1,8.3,11.2,20.6,11.2,33.8v0.5c0,3.3-0.3,6.5-0.8,9.7
						c-8.8,54.7-53.2,70.5-99.9,79.4c-52,9.9-448.7,81-474,85.3c-22.4,3.8-78,9.7-111.5-16.1c-20.3-15.6-31.6-36.9-32.8-61.6
						c-2-43,21.6-86.6,66.6-122.9c25.6-20.6,60.9-33.3,85.4-38.3c25.5-5.2,50.7-3.7,73,4.4l2.7,1L237.7,44.2z"/>
					<path fill="#FFFFFF" d="M703.4,112.6l-2.1-4.8l-0.7-1.6c-0.1-0.1-0.2-0.2-0.4-0.3c-0.2-0.1-0.6-0.1-1.1,0.3l-7.7,5.8l0.2-3.2
						c0.5-6.5-0.7-18.2-6.2-23.6c-5.1-5-12.3-7.3-21-6.7c-9.1,0.7-18.8,4.1-25.1,9.3c-9.4,7.7-15.3,21.3-15.3,21.3s0.5-4.3,0.5-8.2
						c0-6.4-1.7-11.5-5.2-15c-4.1-4.4-10.5-6.2-18.8-5.6c-2.9,0.2-10.2,2.7-14.7,7.2c0.4-1.4,1.8-5.9,1.8-5.9l-31.2,1.6l-8.9,6.8
						l-5.7,25.6c0,0-0.4-1-0.6-1.4c-3.1-6.8-9.2-12.8-18.9-11.9c-5.5,0.5-10.6,2.1-15.2,4.7l-2.9,1.7l6-24.4L472,85.7l-11.7,10.2l2.7-10
						l-42.2,0.4l-1.9,7l-0.1-0.1l-1.6-2.2c-1.6-2.2-4.5-4.1-6.5-4.9c-9-3.6-25.8-0.5-33.8,6.3c-18.5,15.6-19.3,41.8-20.4,57.3
						c-0.8,11.7-1.9,16.4-2.9,17.4c0.3-8.4-4.2-15.5-8.8-18.6c-6.1-4.1-14.8-4.2-22.3-1.7c-16,5.4-19.1,14.5-19.1,14.5l23.9-70.4
						c0,0-9.5,0.3-13.5,0.5c13.1-3.5,20.9-12.3,21.6-24.7c0,0,0-0.6,0-0.6c0-0.2,0-0.4,0-0.5c0-6.2-2.8-12.5-7.7-16.9
						c-3.7-3.3-12.1-6.8-20.4-6.9c-4.5,0-10.4,0.9-15.9,2.5c-7.3,2.1-21.6,10.1-23.3,25.6c-0.8,7.6,0.3,12.7,4.1,17.5
						c1.6,2,5.1,4.3,9.4,5.2c-5.4,0.2-9.7,0.3-10,0.3c-0.7,0.6-6.9,6.4-7.4,6.8c-0.9,2.8-6.7,21.8-10.8,32.4
						c-3.6,9.4-7.8,18.4-12.4,26.4c-13.5,23.7-30.8,38.9-49.9,44c-13.7,3.7-23.6,3.4-31.9-0.8c-8.4-4.2-14.4-17-14.3-30.4
						c0.1-14.1,6.3-32.7,10.5-40.9c14.5-28.5,26.1-35.8,36.5-39.6c8.6-3.1,14.6-2.3,15.9-1.5c-2.1,1.2-3.8,2.2-3.8,2.2
						c-14.8,8.6-24.7,22.5-25.8,36.5c-0.7,8.8,1.9,16.7,7.3,22.3c5.3,5.5,13.2,8.1,22.2,7.2c20.4-1.9,39.5-21.7,40.8-42.4
						c0.8-11.7-2.2-21.2-8.8-28.4c-11-12-24-16.2-47.8-15.6c-21.8,0.6-46.3,9-67.3,23.1c-17.9,12-41.7,34-53.2,70.3
						c-6.8,21.5-1.3,48.9,12.6,62.4c18.6,18,42.1,22.7,78.6,15.5c24.7-4.9,48.8-18.8,71.6-41.6l2-2l0.4,2.8c0.8,4.9,2.5,9,5.2,12.2
						c4.3,5.3,13.1,11,30.8,8.6c11.8-1.6,25.3-7.7,41.2-18.7l0.8-0.5l0.8,0.5c6.9,4.5,16.3,8.6,33.4,5l0.3-0.1
						c6.4-1.3,23.4-4.9,36.9-24.1c1.8-2.6,3.6-5.7,5.5-10l2.3-5.1l0.4,5.6c0.6,7.8,1.7,13.4,5.6,17.2c4.8,4.7,12,6.6,20.3,5.3
						c16.1-2.5,27.1-15.9,34.1-25.7l1.4-1.9l1.1,2.1c5.7,10.9,20.9,16.2,38.8,13.5c17-2.6,25.5-10.5,32.8-19.3l1.4-1.8l-3.3,13.5l44-6.9
						l8-33c0,0-0.2,8.9,0.8,13.7c1,4.8,3,8.8,4.9,10.7c4.3,4.2,11.5,5,16.1,4.3c7.1-1.1,17.6-4.3,27.3-18.9l1.5-2.3l1,2.5
						c3.1,7.4,14.5,12.3,25,10.6c14.2-2.3,24.9-10.4,31-23.4l0.3-0.6l0.6-0.2c7.3-2.3,14.4-9.9,16.4-17.8
						C703.5,115.9,703.9,113.9,703.4,112.6z M460,96.8c0.1-0.3,0.2-0.6,0.2-0.9C460.3,96,460.2,96.3,460,96.8L460,96.8z"/>
					<g>
						<path fill="#232E83" d="M303,87.2c13.8-0.6,27.3-6.8,28.4-21.1c0.7-9.6-6.6-19.5-21.8-20.4c-8.4-0.5-16.1,1.5-21.6,5.7
							c-4.2,3.2-7.1,7.5-8.4,13c-1.5,6.2-0.8,11.4,2.2,15.4C285.7,84.9,293.3,87.6,303,87.2z"/>
						<path fill="#232E83" d="M698.7,110.6c-2,4.4-6.1,8.9-9.7,9.5l-1.6,0.4c0,0,1.5-9.7,1.5-12.6c0.3-13.3-2.8-27.2-23.6-26.1
							c-12.7,0.7-31.3,11.8-34.2,35.7l0,0.1l0,0.1c0,0.1-0.1,0.3-0.1,0.5c-3.2,8.3-9,20-13.1,20.5c-0.9,0.1-1.7-0.2-2.3-0.8
							c-0.8-0.7-1.2-1.9-1.1-3.4c0.1-2.6,1.1-6.5,2.3-11.6c1.7-6.7,3.1-13,3.8-20c1.5-16.3-8.5-19.5-18.8-18.7
							c-7.6,0.6-14.7,5.1-16.7,7.4l-3.7,4.2c0,0,1.7-8,2.2-10.2c-3,0.1-21.3,0.7-23.2,0.8l-8.2,33.1c-2.8,3.8-6.8,6.9-10.1,7.8l-1.6,0.4
							l-0.2-1.7c-0.2-1.7-0.4-3.3-0.8-5c-0.5-3.7-3.7-16.4-16.1-15.4c-31.9,2.7-31,42.2-11.9,36.6l2.2-0.7l-0.4,2.4
							c-2.5,15.7-9.3,24-18.4,25.2c-1.4,0.2-2.7-0.2-3.7-1.2c-1.6-1.5-2.5-4.4-2.4-7.8c0.2-7.7,15.5-71.9,15.5-71.9s-27.6,1-29.6,1.1
							c-1.4,5.9-7.5,31.4-7.6,31.5l-0.9,3.4c-1.2,4.3-2,7.2-2.5,10.2l0,0.1l0,0.1c-0.1,0.2-0.1,0.4-0.2,0.5c-4.6,12.1-12.8,25.2-19,25.9
							c-1.2,0.1-2.3-0.2-3.1-1c-0.9-0.8-1.4-2.1-1.3-3.6c0,0,0-0.1,0-0.1l0.5-5.3c0,0,15.8-57.5,16.8-61.3c-3.5,0.1-33.8,0.7-33.8,0.7
							l-3,10.5c-0.8-8.5-18.8-20-32.3-7.8c-14.3,12.9-18.2,32.2-20.4,51.2c0,0-0.2,0.7-0.2,0.8c-3,22.9-8.3,38.6-24.3,40.7
							c-3.6,0.5-6.9,0.3-9.2-1.1c5.5-4.2,8.9-7.6,11.5-12c5.9-9.8,3.2-18.4-2.9-22.5c-12.4-8.1-25.9,1.8-28.3,14.4
							c-1.5,7.7,1.2,14.2,3.6,18c-4.3,2.4-7.7,5.1-13.6,5.9c-3.5,0.5-7-1.3-8.1-2.9c-3.5-4.8,1.2-20.1,1.9-22.1c0,0,21.9-65.1,23.2-69.1
							c-3.8,0.2-44.1,1.5-46.1,1.6c-1.4,4.1-6.5,20-11.7,34.7c-10.3,28.7-40.3,68-71.1,75.9c-16.4,4.3-29.2,3.5-37.4-1.5
							c-11.1-6.8-17.4-23.2-13.1-46.1c3-16.2,5.7-22.2,10-31.4c25.8-54.9,67.3-42.5,64.7-38.3c-4.2,1.4-7.9,3.6-7.9,3.6
							c-6.6,3.5-17.3,13.9-17.7,26.8c-0.3,8.3,2,15,6.5,19.4c4.4,4.3,10.9,6.3,18.2,5.6c16.4-1.5,29.7-13.8,30.3-32.1
							c0.3-9.9-2.4-18-8.2-24c-9.2-9.6-20-14.4-44-13.7c-37,1-93.5,27.6-109.7,86.4c-5.5,19.9,1.7,43.8,13.6,54.7
							c16.5,15.1,37,20.7,72.9,13.9c32-6,58.2-27.8,77.5-50.7c0,0.4,0,0.7-0.1,1.1c-4.5,16.8,1.7,34.7,29.7,31.1
							c11.7-1.5,32.3-8.2,46.7-19.4c6.6,5.8,18,9.1,31,7.2c5.3-0.8,19-4.4,30.9-21.6c9.5-13.6,14.2-40,16.8-54.3c1.2-6.6,2-11,3-12.9
							c0.9-1.7,1.7-3.8,4.2-4.9c5-2.1,7.6,2.8,6.6,6.4c-1,3.6-6.2,21.9-6.2,21.9l-1,2.9c-3.1,8.9-6.5,19-6.8,29.6
							c-0.2,7.8,1.8,14,5.9,17.8c3.8,3.5,9.2,4.9,15.6,4c17.6-2.6,30.2-20.4,36.1-30.9l0.8-1.5l1.2-2.1l0.7,3.9
							c2.8,15.6,15.1,21.8,33.5,19.1c23.9-3.5,38.3-15.9,42.7-37.5l0.2-0.9l0.8-0.3c2.2-0.8,4.4-2,6.6-3.4l1.8-1.2l-8.5,34.5
							c3.4-0.5,25.8-4.3,27.6-4.5c0.5-2.1,10.3-44,10.3-44c1.7-7.3,6.4-21.1,11.8-21.4c1-0.1,2,0.3,2.8,1.2c1.2,1.2,1.8,3.2,1.8,5.5
							c-0.1,4.6-1.5,9.7-3.2,15.6c-2,7.1-4.1,15.9-4.9,24.5c-1.3,14.6,10.9,14.5,14.6,13.9c8.7-1.3,20.2-5.8,30-27.4l0.5,2.3
							c2.5,12,13.2,19.1,24.4,17.3c13.8-2.1,23.2-9.6,28.4-22l0.4-0.8l0.8-0.1c5.4-0.6,12.9-6.9,14.8-14.2
							C699.8,113.4,699.7,111.9,698.7,110.6z M332.8,156.6c4.9,0.2,6.9,3.3,4.2,9.3c-1.4,3-5.2,7.5-10.3,11.3
							C320.4,167.3,326.5,156.3,332.8,156.6z M516.2,130.4c-0.9,1.3-2.9,2.2-6.1,2.7c-3.3,0.6-4.1-1.2-4.2-2c-0.7-2.9,2.4-7.5,5.2-8.8
							c1.6-0.7,3.4-0.4,3.9,0.2C517.3,125.1,516.2,130.4,516.2,130.4z M668.3,129c-2,6-4.7,12.4-10,13.1c-1.8,0.2-3.3-0.2-4.5-1.2
							c-1.8-1.6-2.6-3.1-2.4-8.6c0.3-12.8,7-44.7,16-45.1c1.2-0.1,2.4,0.4,3.3,1.4c1.9,2.1,2.7,6.1,2.1,10.9l-0.1,0.6
							c0,0-9.5,0.4-10.5,14.5c-0.6,8.5,6.5,12.5,6.5,12.5L668.3,129z M670.8,119.6c-3.1-1.9-2.9-7.5-1.3-9.6c0.8-1.1,2.1-1.2,2.8-0.8
							C672.2,112.6,672,115.7,670.8,119.6z"/>
					</g>
				</g>
			</svg>
       		</span>
				</a>
			);
		default :
			return (
				<span id="logo">
					<a href="https://fora.ua">

							<svg xmlns="SVG namespace " id="logo-svg" x="0px" y="0px" viewBox="0 0 200 59.3" fill="none">
  							<path
									d="M9.65416 46.9742H94.0309C98.8011 46.9742 102.685 43.0904 102.685 38.3202V11.6804C102.685 6.91025 98.8011 3.02637 94.0309 3.02637H9.65416C4.88402 3.02637 1.00013 6.91025 1.00013 11.6804V38.3202C0.974064 43.0904 4.85795 46.9742 9.65416 46.9742Z"
									fill="#00B15E"/>
  							<path
									d="M48.4671 16.6587H48.3629C46.3036 16.6587 44.5311 17.3364 43.0714 18.6658C41.6117 19.9952 40.8818 21.6374 40.8818 23.5141V26.3814C40.8818 28.2582 41.6117 29.9004 43.0714 31.2298C44.5311 32.5592 46.3036 33.2369 48.3629 33.2369H48.4671C50.5264 33.2369 52.2989 32.5592 53.7586 31.2298C55.2183 29.9004 55.9482 28.2582 55.9482 26.3814V23.5141C55.9482 21.6374 55.2183 19.9952 53.7586 18.6658C52.325 17.3103 50.5264 16.6587 48.4671 16.6587ZM48.4671 29.1705H48.3629C47.5027 29.1705 46.7989 28.9099 46.1994 28.3625C45.5998 27.8151 45.3131 27.1634 45.3131 26.3814V23.5141C45.3131 22.7321 45.5998 22.0805 46.1994 21.5331C46.7989 20.9857 47.5027 20.725 48.3629 20.725H48.4671C49.3013 20.725 50.0051 20.9857 50.6046 21.5331C51.2041 22.0805 51.5169 22.7321 51.5169 23.5141V26.3814C51.5169 27.1634 51.2302 27.8151 50.6046 28.3625C50.0051 28.9099 49.3273 29.1705 48.4671 29.1705Z"
									fill="white"/>
  							<path
									d="M37.3367 18.6664C35.9291 17.337 34.2088 16.6593 32.2277 16.6593H32.1234C31.0026 16.6593 29.9339 16.8938 28.9434 17.363C28.5784 16.7114 27.8746 16.2422 27.0666 16.2422C26.2585 16.2422 25.5547 16.6853 25.1898 17.363C24.1993 16.8938 23.1306 16.6593 22.0097 16.6593H21.8794C19.8983 16.6593 18.204 17.337 16.7964 18.6664C15.3889 19.9957 14.6851 21.6379 14.6851 23.5147V26.382C14.6851 28.2588 15.3889 29.901 16.7964 31.2303C18.204 32.5597 19.9244 33.2374 21.8794 33.2374H21.9836C23.0263 33.2374 23.9907 33.0289 24.9291 32.6379V35.2967C24.9291 35.8441 25.1377 36.3393 25.5547 36.7564C25.9718 37.1474 26.4931 37.3559 27.0666 37.3559C27.5879 37.3559 28.0571 37.1995 28.4742 36.8607C28.8652 36.5479 29.0998 36.1308 29.178 35.6616C29.178 35.5574 29.204 35.427 29.204 35.3228V32.664C30.1163 33.055 31.1069 33.2635 32.1234 33.2635H32.2277C34.2088 33.2635 35.9291 32.5858 37.3367 31.2564C38.7443 29.927 39.4481 28.2848 39.4481 26.4081V23.5408C39.4742 21.6379 38.7443 19.9957 37.3367 18.6664ZM24.9552 26.382C24.9552 27.164 24.6685 27.8156 24.0689 28.363C23.4955 28.9104 22.8178 29.1711 22.0097 29.1711H21.8794C21.0713 29.1711 20.3936 28.9104 19.7941 28.363C19.2206 27.8156 18.9339 27.164 18.9339 26.382V23.5147C18.9339 22.7327 19.2206 22.0811 19.7941 21.5337C20.3675 20.9863 21.0452 20.7256 21.8794 20.7256H21.9836C22.7917 20.7256 23.4694 20.9863 24.0429 21.5597C24.6163 22.1071 24.9031 22.7588 24.9031 23.5408V26.382H24.9552ZM35.1993 26.382C35.1993 27.164 34.9125 27.8156 34.3391 28.363C33.7656 28.9104 33.0879 29.1711 32.2538 29.1711H32.1495C31.3415 29.1711 30.6637 28.9104 30.0903 28.363C29.5168 27.8156 29.2301 27.164 29.2301 26.382V23.5147C29.2301 22.7327 29.5168 22.0811 30.0903 21.5337C30.6637 20.9863 31.3415 20.7256 32.1495 20.7256H32.2538C33.0618 20.7256 33.7656 20.9863 34.3391 21.5337C34.9125 22.0811 35.1993 22.7327 35.1993 23.5147V26.382Z"
									fill="white"/>
  							<path
									d="M70.2331 18.6664C68.7734 17.337 66.9748 16.6593 64.9156 16.6593H64.8113C63.5862 16.6593 62.4132 16.9199 61.3445 17.4152C60.9796 16.7114 60.2758 16.2422 59.4416 16.2422C58.2687 16.2422 57.3042 17.2066 57.3042 18.3796V18.6664V20.0218V35.3228C57.3042 35.8701 57.5127 36.3654 57.9559 36.7825C58.3729 37.1474 58.9203 37.3559 59.5198 37.3559C60.1194 37.3559 60.6407 37.1474 61.1099 36.7564C61.5269 36.3654 61.7355 35.8701 61.7355 35.3228V32.6379C62.6739 33.0289 63.7165 33.2374 64.7852 33.2374H64.8895C66.9487 33.2374 68.7473 32.5597 70.207 31.2303C71.6668 29.901 72.3966 28.2588 72.3966 26.382V23.5147C72.4487 21.6379 71.6928 19.9957 70.2331 18.6664ZM67.9914 26.3559C67.9914 27.1379 67.7047 27.7896 67.0791 28.337C66.4796 28.8844 65.7758 29.145 64.9416 29.145H64.8374C63.9772 29.145 63.2734 28.8844 62.6739 28.337C62.0743 27.7896 61.7876 27.1379 61.7876 26.3559V23.4886C61.7876 22.7066 62.0743 22.055 62.6739 21.5076C63.2734 20.9602 63.9772 20.6995 64.8374 20.6995H64.9416C65.7758 20.6995 66.4796 20.9602 67.1051 21.5337C67.7047 22.0811 68.0175 22.7327 68.0175 23.5147V26.3559H67.9914Z"
									fill="white"/>
  							<path
									d="M127.5 47C139.65 47 149.5 37.1503 149.5 25C149.5 12.8497 139.65 3 127.5 3C115.35 3 105.5 12.8497 105.5 25C105.5 37.1503 115.35 47 127.5 47Z"
									fill="#E5412E"/>
  							<path
									d="M137.978 20.178C137.587 19.7088 137.04 19.4221 136.414 19.4221H134.746L130.602 14.5216C130.184 14.0263 129.429 13.9742 128.933 14.3912C128.699 14.5998 128.542 14.8865 128.516 15.1993C128.49 15.5121 128.594 15.8249 128.803 16.0595L131.67 19.4481H123.303L126.17 16.0595C126.379 15.8249 126.483 15.5121 126.457 15.1993C126.431 14.8865 126.274 14.5998 126.04 14.3912C125.805 14.1827 125.493 14.0785 125.18 14.1045C124.867 14.1306 124.58 14.287 124.372 14.5216L120.227 19.4221H118.559C117.959 19.4221 117.386 19.7088 116.995 20.178C116.604 20.6472 116.474 21.2728 116.63 21.8723L118.95 31.6993C119.158 32.6116 119.966 33.2372 120.879 33.2372H134.094C135.007 33.2372 135.815 32.6116 136.023 31.6993L138.343 21.8723C138.5 21.2728 138.369 20.6472 137.978 20.178ZM124.606 29.3794C124.606 29.8747 124.215 30.2657 123.72 30.2657H123.564C123.068 30.2657 122.677 29.8747 122.677 29.3794V23.2799C122.677 22.7846 123.068 22.3936 123.564 22.3936H123.72C124.215 22.3936 124.606 22.7846 124.606 23.2799V29.3794ZM128.438 29.3794C128.438 29.8747 128.047 30.2657 127.552 30.2657H127.395C126.9 30.2657 126.509 29.8747 126.509 29.3794V23.2799C126.509 22.7846 126.9 22.3936 127.395 22.3936H127.552C128.047 22.3936 128.438 22.7846 128.438 23.2799V29.3794ZM132.296 29.3794C132.296 29.8747 131.905 30.2657 131.41 30.2657H131.253C130.758 30.2657 130.367 29.8747 130.367 29.3794V23.2799C130.367 22.7846 130.758 22.3936 131.253 22.3936H131.41C131.905 22.3936 132.296 22.7846 132.296 23.2799V29.3794Z"
									fill="white"/>
  							<path
									d="M86.7854 16.3979C86.0034 16.3979 85.3256 16.815 84.9607 17.4145C84.9607 17.4145 84.9607 17.4406 84.9346 17.4406C83.8659 16.9193 82.6929 16.6586 81.4418 16.6586H81.3375C79.2782 16.6586 77.5057 17.3363 76.046 18.6657C74.5863 19.9951 73.8564 21.6373 73.8564 23.5141V26.3814C73.8564 28.2581 74.5863 29.9003 76.046 31.2297C77.5057 32.5591 79.2782 33.2368 81.3375 33.2368H81.4418C82.6929 33.2368 83.8659 32.9761 84.9346 32.4548C85.3256 33.0804 86.0034 33.4975 86.7854 33.4975C87.9844 33.4975 88.9489 32.533 88.9489 31.334V31.2036V30.7605V19.1349V18.6657V18.5615C88.9489 17.3624 87.9844 16.3979 86.7854 16.3979ZM84.5176 26.3814C84.5176 27.1633 84.2309 27.815 83.6053 28.3624C83.0057 28.9098 82.3019 29.1705 81.4678 29.1705H81.3636C80.5034 29.1705 79.7996 28.9098 79.2 28.3624C78.6005 27.815 78.3138 27.1633 78.3138 26.3814V23.5141C78.3138 22.7321 78.6005 22.0804 79.2 21.533C79.7996 20.9856 80.5034 20.725 81.3636 20.725H81.4678C82.3019 20.725 83.0057 20.9856 83.6313 21.533C84.2309 22.0804 84.5436 22.7321 84.5436 23.5141V26.3814H84.5176Z"
									fill="white"/>
						</svg>
					</a>
				</span>
			)
	}
};

export default Logo;
