type mainMenuType = {
	title: string,
	tagGA: string,
	path: string,
	subLinks: {
		title: string,
		tagGA: string,
		path: string,
	}[],
};

type menuDataType = {
	mainMenu: mainMenuType[];
	additionalMenu: {
		path: string,
		subdomain: string,
		title: string,
		links: mainMenuType[];
	}[];
}

export const menuData:menuDataType = {
	"mainMenu": [
		{
			"title": "Акції",
			"tagGA": "",
			"path": "promotions",
			"subLinks": [],
		},
		{
			"title": 'Робота у ФОРІ',
			"tagGA": "",
			"path": "work/vacancies",
			"subLinks": [],
		},
		{
			"title": "Подарункові сертифікати",
			"tagGA": "",
			"path": "gift-certificates",
			"subLinks": [],
		},
		{
			"title": "Ваша найближча ФОРА",
			"tagGA": "",
			"path": "vasha-nayblyzhcha-fora",
			"subLinks": [],
		},
	],
	"additionalMenu": [
		{
			"path": "",
			"subdomain": "my.fora.ua",
			"title": "Персональна сторінка",
			"links": [
				{
					"title": "ФОРА CLUB",
					"tagGA": "",
					"path": "/",
					"subLinks": [],
				},{
					"title": "Бонус від ФОРА CLUB",
					"tagGA": "",
					"path": "bonus",
					"subLinks": [],
				},
				{
					"title": "Персональні пропозиції",
					"tagGA": "",
					"path": "special-proposals",
					"subLinks": [],
				},

				{
					"title": "Moї покупки",
					"tagGA": "",
					"path": "purchases",
					"subLinks": [],
				},
				{
					"title": "Копійковий рахунок",
					"tagGA": "",
					"path": "moneybox",
					"subLinks": [],
				},
				{
					"title": "Персональна інформація",
					"tagGA": "",
					"path": "personal-info",
					"subLinks": [
						{
							"title": "Персональні дані",
							"tagGA": "",
							"path": "info",
						},
						// {
						// 	"title": "Зміна пароля",
						// 	"tagGA": "",
						// 	"path": "change-password",
						// },
						{
							"title": "Мої картки",
							"tagGA": "",
							"path": "cards",
						},
						{
							"title": "Номер для поповнення",
							"tagGA": "",
							"path": "recharge-numbers",
						},
						{
							"title": "Захист кешбек бонусу",
							"tagGA": "",
							"path": "protection",
						},
					],
				},
			]
		},
	],
};