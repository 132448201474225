import React, {FC, useCallback, useState} from "react";

import {BalancePreviewType} from "./types";
import {defaultColors, donutCircleColors} from "../../constants";
import getTemplateText, {FormattedHTMLMessage} from "../../services/TextService";

import "./BalancePreview.styl";


const BalancePreview: FC<BalancePreviewType> = ({currentBalance, isBalanceHistory}) => {
	const {balance, balanceLines} = currentBalance || {balance: [], balanceLines: []};
	const totalBalance = isBalanceHistory ? balance * 100 : balance;
	const usedBalanceLines = (balanceLines || []).filter(({balance}) => !!balance);
	const [activeBalanceLine, setActiveBalanceLine] = useState("");

	/***
	 * handle mouse event
	 */
	const handleMouseEnter = useCallback((activeBalanceLine) => {
		setActiveBalanceLine(activeBalanceLine);
	}, [setActiveBalanceLine]);
	const handleMouseLeave = useCallback(() => {
		setActiveBalanceLine("");
	}, [setActiveBalanceLine]);

	/***
	 * render balance lines
	 */
	const renderBalanceLines = useCallback(() => (
		usedBalanceLines.map(({balanceName, name, codeName, balance}, index) => {
			const className = codeName === activeBalanceLine ? "balance-line active" : "balance-line";
			const balanceIconColor = donutCircleColors[codeName] || defaultColors[index];

			return (
				<li key={codeName}
						onMouseEnter={() => handleMouseEnter(codeName)}
						onMouseLeave={handleMouseLeave}
						className={className}
				>
					<div className="balance-line_icon">
						<svg width="15px" height="15px" viewBox="0 0 40 40" className="icon-balance">
							<circle cx="20" cy="20" r="19" fill={balanceIconColor}/>
						</svg>
					</div>
					<div className="balance-line_text">
						<div className="balance-line_title">
							{balanceName || name}
						</div>
						<div className="balance-line_value">
							<FormattedHTMLMessage id="points" values={{points: balance}}/>
						</div>
					</div>
				</li>
			)
		})
	), [usedBalanceLines, activeBalanceLine, handleMouseEnter, handleMouseLeave]);

	/***
	 * renders ring diagram
	 * explanation of the construction of the ring diagram at the link below:
	 * https://devacademy.ru/article/krugovaia-i-koltsevaia-svg-diagrammy-na-html5-s-nulia/
	 */
	const renderDiagram = useCallback(() => {
		const CIRCLE_LENGTH = 100;
		const onePercentValue = totalBalance / CIRCLE_LENGTH;
		let currentDashoffset = CIRCLE_LENGTH;

		return (
			<svg width="100%" height="100%" viewBox="0 0 40 40" className="diagram">
				<circle cx="20" cy="20" r="15.91549430918954" fill="transparent" stroke="#606060" strokeWidth="2"
								className="default-ring"/>
				{usedBalanceLines.map(({balanceName, codeName, balance}, index) => {
					const arcLength = balance / onePercentValue;
					const gapLength = CIRCLE_LENGTH - arcLength;
					const arcColor = donutCircleColors[codeName] || defaultColors[index];
					const className = codeName === activeBalanceLine ? "diagram-arc active" : "diagram-arc";
					if (index > 0) {
						currentDashoffset -= gapLength;
					}

					return (
						<circle cx="20" cy="20" r="15.91549430918954" fill="transparent" stroke={arcColor} strokeWidth="2"
										strokeDasharray={`${arcLength} ${gapLength}`} strokeDashoffset={currentDashoffset}
										key={codeName} className={className}/>
					)
				})}
			</svg>
		)
	}, [usedBalanceLines, activeBalanceLine, totalBalance]);


	if (!balance || !usedBalanceLines || usedBalanceLines.length === 0) {
		return null
	}
	return (
		<div className="balance-preview">
			<div className="balance-preview_top-wrap">
				{!isBalanceHistory &&
				<div className="balance-preview_content">
					<h3 className="content-title">Накопичується</h3>
					<div className="content-value">
						{(balance / 100).toFixed(2)}
						<span className="content-currency">{getTemplateText("hrn")}</span>
					</div>
					<div className="content-description">
						Користуйтеся персональними пропозиціями та отримуйте більший кешбек бонус за свої покупки в
						<a href="https://fora.ua/" rel="noopener noreferrer"> «Форі»</a>.
					</div>
					<h4 className="content-diagram-title">Баланс</h4>
				</div>
				}
				<div className="balance-preview_diagram">
					{renderDiagram()}
					<div className="diagram-value_wrap">
						<span className="diagram-value">{balance}</span><br/>
						<span className="diagram-currency">{isBalanceHistory ? "грн" : "Балів"}</span>
					</div>
				</div>
			</div>

			<ul className="balance-preview_balance-lines">
				{renderBalanceLines()}
			</ul>
		</div>
	)
};

export default BalancePreview;